import logo from './logo.svg';
import './App.css';
import Initialization from './components/initialization';
import Panel from './components/panel';
import Home from './components/home';
import Login from './components/login'
import axios from 'axios'

import React, { useState, useEffect } from 'react';
import ModZone from './components/modzone';

function App() {
  const [auth, setAuth] = useState(null)

  useEffect(() => {
    axios.get('/auth/current-session').then(({ data }) => {
      setAuth(data);
    })
  }, [])

  if (auth === null) {
    return <Initialization />
  }
  if (auth) {
    return <ModZone user={auth} />
  }
  return <Login />
}

export default App;
