import React from 'react'

function Initialization() {
    return (
        <div>
            <h1>ZeldathonBot Loading...</h1>
        </div>
    )
}

export default Initialization;