import React, { useState, useEffect } from 'react'
import { Container, Navbar, Modal, Col, Image, Row, Table, Button, Form, InputGroup } from 'react-bootstrap'
import axios from 'axios'

function TrainView(props) {

    const [sortMode, setSortMode] = useState("agenew")
    const [trainList, setTrainList] = useState([])

    const refreshTrains = () => {
        props.socket.emit('trains:find')
    }

    useEffect(() => {
        props.socket.emit('trains:find')
        props.socket.on('trains:list', (trainList) => {
            if (sortMode === "agenew") {
                trainList.sort((a,b) => 
                    b.endTime - a.endTime
                )
            }
            if (sortMode === "ageold") {
                trainList.sort((a,b) => 
                    a.endTime - b.endTime
                )
            }
            if (sortMode === "length") {
                trainList.sort((a,b) => 
                    b.donationTotal > a.donationTotal ? 1 : 0
                )
            }
            if (sortMode === "raised") {
                trainList.sort((a,b) => 
                    b.amountRaised > a.amountRaised ? 1 : 0
                )
            }
            setTrainList(trainList)
        })
    }, [])

    const trainListView = trainList.map((train) =>
        <tr>
            <td>{train.startTime}</td>
            <td>{train.endTime}</td>
            <td>{train.donationTotal}</td>
            <td>{train.amountRaised}</td>
        </tr>)

    return (
        <>
            <Row>
                <Col>
                    <h2>Donation Train History</h2>
                </Col>
                <Col>
                <Form>
                                <Form.Group controlId="formLevel">
                            <Form.Label>Sort Mode</Form.Label>
                            <Form.Control onChange={e => {setSortMode(e.target.value);}} as="select" value={sortMode}>
                                <option value="ageold">
                                    Age (Oldest)
                                </option>
                                <option value="agenew">
                                    Age (Newest)
                                </option>
                                <option value="length">
                                    Length
                                </option>
                                <option value="raised">
                                    Amount Raised
                                </option>
                            </Form.Control>
                            <br />
                            <Button onClick={refreshTrains}>Refresh</Button>
                        </Form.Group>
                            </Form></Col>
            </Row>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Start Time</th>
                        <th>End Time</th>
                        <th>Donation Count</th>
                        <th>Amount Raised</th>
                    </tr>
                </thead>
                <tbody>
                    {trainListView}
                </tbody>
            </Table>
        </>
    )
}

export default TrainView;