import React, { useState } from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import { propTypes } from 'react-bootstrap/esm/Image';
import AnalyticView from './analyticview';
import BlacklistView from './blacklistview';
import CommandView from './commandview'
import SettingsView from './settingsview';
import TrainView from './trainview';

function ControlledTabs(props) {
    const [key, setKey] = useState('commands');
  
    return (
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
      >
        <Tab eventKey="commands" title="Commands">
          <br />
          <CommandView socket={props.socket} user={props.user} />
        </Tab>
        <Tab eventKey="events" title="Analytics">
        <br />
          <AnalyticView socket={props.socket} user={props.user} />
        </Tab>
        <Tab eventKey="moderation" title="Moderation">
        <br />
          <BlacklistView socket={props.socket} user={props.user} />
        </Tab>
        <Tab eventKey="donationtrains" title="Donation Trains">
        <br />
          <TrainView socket={props.socket} user={props.user} />
        </Tab>
      </Tabs>
    );
  }

export default ControlledTabs;