import React, { useState, useEffect } from 'react'
import { Container, Navbar, Modal, Col, Image, Row, Table, Button, Form, InputGroup } from 'react-bootstrap'
import axios from 'axios'

function BlacklistView(props) {
    //FormValues
    const [word, setWord] = useState('');
    const [caseSensitivity, setCaseSensitivity] = useState(false);
    const [regex, setRegex] = useState(false);

    const [blacklist, setBlacklist] = useState([])

    const compileData = () => {
        return {
            word,
            caseSensitivity,
            regex
        }
    }

    const handleSubmit = (evt) => {
        evt.preventDefault()
        props.socket.emit('blacklist:create', compileData());
    }

    const removeFromList = (word) => {
        props.socket.emit('blacklist:delete', word)
    }

    useEffect(() => {
        props.socket.emit('blacklist:find')
        props.socket.on('blacklist:list', (blacklist) => {
            console.log(blacklist)
            setBlacklist(blacklist)
        })
    }, [])

    const wordBlacklist = blacklist.map((word) =>
        <tr>
            <td>{word.caseWord}</td>
            {word.regex ? <td>N/A -- Regex</td> : <td>{word.caseSensitive ? 'True' : 'False'}</td>}
            <td><Button variant='danger' onClick={() => { removeFromList(word) }}>Delete</Button></td>
        </tr>)

    return (
        <>

            <Row>
                <Col>
                    <Form>
                        <Form.Group controlId="formRegex">
                        <Form.Label>Regex:</Form.Label>
                        <Form.Check checked={regex}
                                    id="caseSensitivity"
                                    label="Regex"
                                    onChange={e => setRegex(e.target.checked)}></Form.Check>
                        </Form.Group>
                        <Form.Group controlId="formWord">
                            <Form.Label>Add {regex ? 'Regex' : 'String'}</Form.Label>
                            <InputGroup className="mb-3">
                                {!regex ? 
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Case Sensitive:</InputGroup.Text>
                                    <InputGroup.Checkbox checked={caseSensitivity}
                                        id="caseSensitivity"
                                        label="Case Sensitive"
                                        onChange={e => setCaseSensitivity(e.target.checked)}></InputGroup.Checkbox>
                                </InputGroup.Prepend> : null }
                                <Form.Control onChange={e => setWord(e.target.value)} type="text" placeholder={regex ? 'regex' : 'string'} value={word} />
                                <InputGroup.Append>
                                    <Button type="submit" onClick={handleSubmit} >Create Entry</Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </Form.Group>
                    </Form>
                </Col>
            </Row>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>String/Regex</th>
                        <th>Case Sensitive</th>
                        <th>Options</th>
                    </tr>
                </thead>
                <tbody>
                    {wordBlacklist}
                </tbody>
            </Table>
        </>
    )
}

export default BlacklistView;