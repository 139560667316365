import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import Toolbar from './toolbar'
import io from 'socket.io-client'
import axios from 'axios'
import Panel from './panel'

var socket;

function ModZone(props) {
    const [socketState, setSocket] = useState(false)
    const [flavor, setFlavor] = useState('Connecting to socket.')

    useEffect(() => {
        socket = io('/', { transports: ['websocket'], path: '/socket' });

        socket.on('authenticated', (data) => {
            setSocket(true);
            setFlavor('Authenticated.')
        })
    
        socket.on('connect', (data) => {
            setSocket(false);
            setFlavor('Authenticating...')
            axios.get('/auth/socket-token').then(({ data }) => {
                socket.emit('authenticate', data.socketKey)
            })
        })
    
        socket.on('reconnect_attempt', (attempt) => {
            setSocket(false)
            setFlavor('Reconnecting... ('+attempt+')')
        })
    
        socket.on('reconnect', (attempt) => {
            setSocket(false)
            setFlavor('Reconnecting... ('+attempt+')')
        })
    
        socket.on('connect_error', (data) => {
            setSocket(false);
            setFlavor('A connection error has occurred.')
        })
    
        socket.on('disconnect', () => {
            setSocket(false);
            setFlavor('Disconnected from socket.')
        })
    
        socket.on('reconnecting', () => {
            setSocket(false)
        })
    }, []);

    if (!socketState) {
        return <h1>Connecting to Socket...</h1>
    } else {
        return <Panel user={props.user} socket={socket}></Panel>
    }
}

export default ModZone;