import React, { useState, useEffect } from 'react'
import { Container, Navbar, Modal, Col, Image, Row, Table, Button, Form } from 'react-bootstrap'
import axios from 'axios'

function CommandView(props) {
    const [globalTiming, setGlobalTiming] = useState(false)
    const [coolDown, setCoolDown] = useState(0)

    //FormValues
    const [invocation, setInvocation] = useState('');
    const [description, setDescription] = useState('');
    const [response, setResponse] = useState('');
    const [timing, setTiming] = useState('');
    const [timingEnabled, setTimingEnabled] = useState(false);
    const [discord, setDiscord] = useState(false);
    const [twitch, setTwitch] = useState(true);
    const [level, setLevel] = useState(3);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [commands, setCommands] = useState([])
    const [selected, setSelected] = useState({})

    const compileData = () => {
        return {
            invocation,
            description,
            response,
            timing: timing.split(' '),
            timingEnabled,
            discord,
            twitch,
            level
        }
    }

    const updateGlobalTiming = (checked) => {
        props.socket.emit('settings:set', { key: 'twitchAutoMessages', value: checked });
    }

    const updateCoolDown = () => {
        props.socket.emit('settings:set', { key: 'twitchCooldown', value: coolDown })
    }

    const handleDelete = (evt) => {
        evt.preventDefault()
        props.socket.emit('commands:delete', selected)
        handleClose()
    }

    const handleSubmit = (evt) => {
        evt.preventDefault()
        if (!selected) {
            props.socket.emit('commands:create', compileData());
        } else {
            props.socket.emit('commands:update', { old: selected, new: compileData() })
        }
        handleClose()
    }

    const selectAndEdit = (command) => {
        setInvocation(command.invocation)
        setDescription(command.description)
        setResponse(command.response)
        setTiming(command.timing.join(' '))
        setTimingEnabled(command.timingEnabled)
        setDiscord(command.discord)
        setTwitch(command.twitch)
        setLevel(command.level)
        setSelected(command)
        handleShow()
    }

    useEffect(() => {
        props.socket.on('settings:list', (settings) => {
            settings.forEach((setting) => {
                if (setting.key === 'twitchAutoMessages') {
                    setGlobalTiming(setting.value)
                }
                if (setting.key === 'twitchCooldown') {
                    setCoolDown(setting.value)
                } 
            })
        })
        props.socket.emit('commands:find')
        props.socket.on('commands:list', (commands) => {
            console.log(commands)
            setCommands(commands)
        })
    }, [])

    const commandList = commands.map((command) =>
        <tr>
            <td>{command.invocation}</td>
            <td>{command.description}</td>
            <td>{command.response}</td>
            <td>{(command.timingEnabled) ? (globalTiming ? '' : 'Disabled Globally -- ') + command.timing.join(' ') : 'Disabled'}</td>
            <td>{(!command.discord && !command.twitch ? 'None' : '') + (command.discord ? 'Discord ' : '') + (command.twitch ? 'Twitch' : '')}</td>
            <td><Button onClick={() => { selectAndEdit(command) }}>Edit</Button></td>
        </tr>)

    return (
        <>

            <Row>
                <Col><Button onClick={() => { setSelected(null); handleShow() }}>Create New</Button></Col>
                <Col>
                    <Form>
                        
                            <Form.Row>
                            <Col xs="auto">
                                <Form.Check checked={globalTiming}
                                    type="switch"
                                    id="setGlobalTiming"
                                    label={"Auto Messages " + (globalTiming ? "Enabled" : "Disabled")}
                                    onChange={e => { setGlobalTiming(e.target.checked); updateGlobalTiming(e.target.checked) }} />
                                </Col>
                                
                            </Form.Row>
                            <Form.Row>
                            <Col xs="auto">
                                <Form.Control
                                    name="coolDown"
                                    type="number"
                                    maxLength="10"
                                    value={coolDown}
                                    onChange={e => setCoolDown(e.target.value)}>
                                </Form.Control>
                                </Col>
                                <Col>
                                    <Button onClick={(e) => { updateCoolDown() }}>Set Cooldown</Button>
                                </Col>
                            </Form.Row>
                    </Form>
                </Col>
            </Row>
                <br />
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Invocation Phrase</th>
                            <th>Description</th>
                            <th>Response</th>
                            <th>Timing</th>
                            <th>Availability</th>
                            <th>Options</th>
                        </tr>
                    </thead>
                    <tbody>
                        {commandList}
                    </tbody>
                </Table>

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{selected ? 'Edit Command' : 'Create Command'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="formInovcation">
                                <Form.Label>Invocation Phrase (without prefix)</Form.Label>
                                <Form.Control onChange={e => setInvocation(e.target.value)} type="text" placeholder="command" value={invocation} />
                            </Form.Group>
                            <Form.Group controlId="formDescription">
                                <Form.Label>Description (for discord help)</Form.Label>
                                <Form.Control onChange={e => setDescription(e.target.value)} type="text" placeholder="Provides important info!" value={description} />
                            </Form.Group>
                            <Form.Group controlId="formResponse">
                                <Form.Label>Response Text</Form.Label>
                                <Form.Control onChange={e => setResponse(e.target.value)} type="text" placeholder="Important info!" value={response} />
                            </Form.Group>
                            <Form.Group controlId="formTiming">
                                <Form.Label>Timings on the hour. (Numbers Separated by spaces)</Form.Label>
                                <Form.Control onChange={e => setTiming(e.target.value)} type="text" placeholder="0 15 30 45" value={timing} />
                                <Form.Check
                                    checked={timingEnabled}
                                    type="switch"
                                    id="timingEnabled"
                                    label="Enabled"
                                    onChange={e => setTimingEnabled(e.target.checked)}
                                />
                            </Form.Group>
                            <Form.Group controlId="formAvailability">
                                <Form.Label>Availability Settings</Form.Label>
                                <Form.Check
                                    checked={twitch}
                                    type="switch"
                                    id="twitchAvailability"
                                    label="Twitch"
                                    onChange={e => setTwitch(e.target.checked)}
                                />
                                <Form.Check
                                    checked={discord}
                                    type="switch"
                                    label="Discord"
                                    id="discordAvailability"
                                    onChange={e => setDiscord(e.target.checked)}
                                />
                            </Form.Group>
                            <Form.Group controlId="formLevel">
                                <Form.Label>Access Level</Form.Label>
                                <Form.Control onChange={e => setLevel(e.target.value)} as="select" value={level}>
                                    <option value={0} >
                                        Everyone
                                </option>
                                    <option value={1} >
                                        Subscribers
                                </option>
                                    <option value={2} >
                                        Moderators
                                </option>
                                    <option value={3} >
                                        Broadcaster
                                </option>
                                </Form.Control>
                            </Form.Group>
                            <hr />
                            <Button type="submit" onClick={handleSubmit} >{selected ? 'Update Command' : 'Create Command'}</Button>
                            {selected ? (<Button onClick={handleDelete} type="submit" variant="danger">Delete Command</Button>) : (<></>)}
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>

                    </Modal.Footer>
                </Modal>
        </>
    )
}

export default CommandView;