import React from 'react'
import { Container } from 'react-bootstrap'
import Toolbar from './toolbar'
import Pages from './pages'

function Panel(props) {
    return (
        <Container>
            <Toolbar user={props.user} />
            <Pages user={props.user} socket={props.socket}></Pages>
        </Container>
    )
}

export default Panel;