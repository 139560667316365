import React, { useState, useEffect } from 'react'
import { Container, Navbar, Modal, Col, Image, Row } from 'react-bootstrap'
import axios from 'axios'

function Toolbar(props) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [version, setVersion] = useState({})

    useEffect(() => {
        axios.get('/auth/version-info').then(({ data }) => {
            setVersion(data);
        })
    }, [])

    return (
        <>
            <Navbar>
                <Navbar.Brand href="#home">{version.appName}</Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    <Navbar.Text>
                        User: <a href="#login" onClick={handleShow}>{props.user.displayName + `(${props.user.id})`}</a>
                    </Navbar.Text>
                </Navbar.Collapse>
            </Navbar>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{version.appName} - Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        {/*<Col xs={2} md={2}>
                            <Image src={props.user.iconURL} roundedCircle fluid />
                        </Col>*/}
                        <Col>
                            <h1>Hello, {props.user.displayName}</h1>
                        </Col>
                    </Row>
                    <hr />
                    <Row><Col xs={12}>
                        <h5>Twitch Login/ID: {props.user.name + ' (' + props.user.id + ')'}</h5>
                    </Col>
                    </Row>
                    <hr />
                    <Row><Col xs={12}>
                        <p>Twitch Bio: {props.user.bio}</p>
                    </Col>
                    </Row>
                    <Row><Col xs={12}>
                        <p>Moderator: {props.user.modstate ? 'Yes' : 'No'}</p>
                    </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <p>Active Channel: #{version.channel}</p>
                    {version.organization + ' // ' + version.appName + '-' + version.environment + '-v' + version.version}
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Toolbar;