import React from 'react'

function Login() {
    return (
        <div>
            <a href="/auth/login/twitch">Login</a>
        </div>
    )
}

export default Login;