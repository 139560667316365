import React, { useState, useEffect } from 'react'
import { Legend, LineChart, Line, YAxis, XAxis, Tooltip, ResponsiveContainer } from 'recharts'
import { Container, Navbar, Modal, Col, Image, Row, Table, Button, Form, InputGroup, Card } from 'react-bootstrap'
import axios from 'axios'
import moment from 'moment'
import { times } from 'lodash-es'

function AnalyticView(props) {
    //FormValues
    const [word, setWord] = useState('');
    const [caseSensitivity, setCaseSensitivity] = useState(false);

    const [snapShots, setSnapShots] = useState([])
    const [timeFrame, setTimeFrame] = useState(60)
    const [record, setRecord] = useState([])

    var timeLength = 60

    const compileData = () => {
        return {
            word,
            caseSensitivity
        }
    }

    const handleSubmit = (evt) => {
        evt.preventDefault()
        props.socket.emit('settings:set', { key: 'recordAnalytics', value: record });
    }

    const removeFromList = (word) => {
        props.socket.emit('blacklist:delete', word)
    }

    const handleSettings = (settings) => {
        settings.forEach((setting) => {
            if (setting.key === 'recordAnalytics') {
                setRecord(setting.value)
            }
        })
    }

    const refreshStatistics = () => {
        timeLength = timeFrame
        updateChart(timeFrame)
    }

    const updateChart = () => {
        console.log(timeFrame)
        let time = new Date().getTime() - (1000*60*timeLength)
        console.log(time)
        props.socket.emit('analytics:find', { time })
    }

    useEffect(() => {
        refreshStatistics()
        props.socket.emit('settings:get')
        props.socket.on('settings:list', (settings) => {
            handleSettings(settings)
        })
        props.socket.on('analytics:list', (snapShots) => {
            snapShots.sort((a, b) => {
                return (a.time - b.time)
            })
            setSnapShots(snapShots)
        })
    }, [])

    return (
        <Container>
            <Row>
                <Col>
                    <Card>
                        <Card.Header>
                            Settings (All settings are local unless otherwise specified)
                    </Card.Header>
                        <Card.Body>
                            <Form>
                                <Form.Group controlId="formWord">
                                    <Form.Check checked={record}
                                        type="switch"
                                        id="setRecord"
                                        label="Record Analytics (Global Setting)"
                                        onChange={e => setRecord(e.target.checked)} />
                                    <hr />
                                    <Button type="submit" onClick={handleSubmit} >Apply</Button>
                                </Form.Group>
                                <hr />
                                <Form.Group controlId="formLevel">
                            <Form.Label>View Age</Form.Label>
                            <Form.Control onChange={e => {setTimeFrame(e.target.value);refreshStatistics()}} as="select" value={timeFrame}>
                                <option value={5}>
                                    5 Minutes
                                </option>
                                <option value={15}>
                                    15 Minutes
                                </option>
                                <option value={30}>
                                    30 Minutes
                                </option>
                                <option value={60} >
                                    1 Hour (Default)
                                </option>
                                <option value={60*6} >
                                    6 Hours
                                </option>
                                <option value={60*12} >
                                    12 Hours
                                </option>
                                <option value={60*24} >
                                    24 Hours
                                </option>
                                <option value={60*24*2}>
                                    2 Days
                                </option>
                                <option value={60*24*3}>
                                    3 Days
                                </option>
                                <option value={60*24*4}>
                                    4 Days
                                </option>
                                <option value={60*24*5}>
                                    5 Days
                                </option>
                                <option value={60*24*6}>
                                    6 Days
                                </option >
                                <option value={60*24*7}>
                                    7 Days
                                </option>
                            </Form.Control>
                            <br />
                            <Button onClick={refreshStatistics}>Refresh</Button>
                        </Form.Group>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={12} lg={12}>
                    <Card>
                        <Card.Header>
                            Chat Statistics (Last {timeFrame} Minutes)
                    </Card.Header>
                        <Card.Body>
                            <ResponsiveContainer width={'100%'} height={500}>
                                <LineChart width={500} height={500} data={snapShots}>
                                    <Line connectNulls={false} name="Twitch Message Count" type="linear" dataKey="twitchMessageCount" stroke="#b642f5"></Line>
                                    <Line connectNulls={false} name="Twitch Unique Users" type="linear" dataKey="twitchUniqueUsers" stroke="#f58d42"></Line>
                                    <Line connectNulls={false} name="Moderation Actions" type="linear" dataKey="modCount" stroke="#f54242"></Line>
                                    <Line connectNulls={false} name="Amount Raised" type="linear" dataKey="donations" stroke="#000000"></Line>
                                    <YAxis></YAxis>
                                    <XAxis dataKey="time" tickFormatter={timeStr => moment(timeStr).format('HH:mm')} ></XAxis>
                                    <Legend verticalAlign="top"></Legend>
                                    <Tooltip labelFormatter={timeStr => moment(timeStr).format('HH:mm:ss')}></Tooltip>
                                </LineChart>
                            </ResponsiveContainer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={12} lg={12}>
                    <Card>
                        <Card.Header>
                            Bot Statistics (Last {timeFrame} Minutes)
                    </Card.Header>
                        <Card.Body>
                            <ResponsiveContainer width={'100%'} height={500}>
                                <LineChart width={500} height={500} data={snapShots}>
                                    <Line connectNulls={false} name="Twitch Command Attempts" type="linear" dataKey="twitchCommandAttempts" stroke="#f54242"></Line>
                                    <Line connectNulls={false} name="Twitch Command Success" type="linear" dataKey="twitchCommandSuccess" stroke="#f58d42"></Line>
                                    <YAxis></YAxis>
                                    <XAxis dataKey="time" tickFormatter={timeStr => moment(timeStr).format('HH:mm')} ></XAxis>
                                    <Legend verticalAlign="top"></Legend>
                                    <Tooltip labelFormatter={timeStr => moment(timeStr).format('HH:mm:ss')}></Tooltip>
                                </LineChart>
                            </ResponsiveContainer>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row>
                
            </Row>
        </Container>
    )
}

export default AnalyticView;